.Board {
    flex-grow: 1;
    background-color: #eee;
    overflow: auto;
}

.loading-placeholder {
    height: 4px;
}

.task-group {
    padding: 25px;
}

.add-task-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 10%;
}

.add-task-group button {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.3);
    margin: 0 15px;
    padding: 5px 2%;
}

.add-task-group div {
    height: 1px;
    flex-grow: 1;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

